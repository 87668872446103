export enum BudgetPermission {
  read = 1,
  markItems = 2,
  manageItems = 4,
  manageUsers = 8,
  manageBudget = 16,
  owner = BudgetPermission.read |
    BudgetPermission.markItems |
    BudgetPermission.manageItems |
    BudgetPermission.manageUsers |
    BudgetPermission.manageBudget,
}
